$darkBlue: #4084d8;
$blueBackground: #e8f2fc;

.header {
  font-size: 1.8rem !important;
  font-weight: 600 !important;
  text-align: center;
  margin-top: 2rem !important;
}

.documentCategory {
  text-align: center;
}

.subHeader {
  font-style: italic;
  text-align: center;
  letter-spacing: 4px;
}

.payrollContainer {
  background-color: white;
  height: 60vh;
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.payrollItem {
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
  display: flex;
  align-items: center;
}

.payrollCell {
  background-color: cornsilk;
  border-radius: 5px;
  height: 30px;
  width: 30px;
  cursor: pointer;
}

.folderIcon {
  color: #2c358d;
  height: 8rem !important;
  width: 8rem !important;
}

.foldersContainer {
  display: flex;
}

.folder {
  margin-left: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.navTitle {
  color: #2c358d;
  font-weight: bold !important;
  margin-top: 2rem !important;
}

.poCard {
  height: 16rem;
  width: 14rem;
  margin: 2rem;
  background-color: white;
}

.iconBox {
  width: 100%;
  height: 11rem;
}

.icon {
  width: 100%;
  height: 100%;
}

.link {
  text-decoration: none;
  color: inherit;
}

.poCardActions {
  padding: 1rem;
  display: flex;
  justify-content: space-between;
}

.poActions {
  padding: 1rem;
  display: flex;
  justify-content: center;
}

.poCardLabel {
  text-align: center;
  margin-top: 0.5rem;
  letter-spacing: 1.2;
  font-size: 1.2rem;
  font-weight: bold;
  color: #9e9e9e;
}

.listItemSelected {
  color: darkBlue;
  background-color: $blueBackground !important;
}

.root {
  cursor: pointer;
  background-color: white;
  text-align: center;
  border-radius: 1rem;
  margin: 1rem;
  &:selected {
    background-color: $blueBackground;
    &:hover {
      background-color: $blueBackground;
    }
  }
}

.aRight {
  width: 100%;
  display: flex;
  justify-content: right;
}

.galleryContainer {
  height: 60vh;
}
