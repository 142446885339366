$darkBlue: #4084d8;
$primaryGrey: #c4c4c4;
$primaryGrey2: #f8f8f8;
$primaryGrey3: #ebebeb;
$textColor: #545252;
$deleteRed2: #f9320d;

.buttonContainer {
  width: 95%;
}

.filterButtonsContainer {
  width: 95%;
  display: flex;
}

.formButton {
  background-color: $darkBlue !important;
  color: white !important;
  font-size: 0.9rem !important;
  text-transform: none !important;
  margin-bottom: 1.5rem !important;
  border-radius: 0.5rem !important;
  width: 94%;
  &:hover {
    background-color: $darkBlue !important;
  }
}

.filterButton {
  background-color: black !important;
  color: white !important;
  font-size: 0.9rem !important;
  text-transform: none !important;
  margin-right: 0.5rem !important;
  margin-bottom: 1.5rem !important;
  border-radius: 0.5rem !important;
  width: 100%;
  &:hover {
    background-color: black !important;
  }
}

.cancelButton {
  background-color: white !important;
  color: black !important;
  font-size: 0.9rem !important;
  text-transform: none !important;
  margin-left: 0.5rem !important;
  margin-bottom: 1.5rem !important;
  border: 2px solid grey !important;
  border-radius: 0.5rem !important;
  width: 100%;
  &:hover {
    background-color: white !important;
    border: 2px solid black !important;
  }
}

.loadingContainer {
  height: 23rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.formHeader {
  height: 3rem;
  font-size: 2rem;
  padding: 16px 0;
  border-bottom: 2px solid #e0e0e0;
}

.formHeaderText {
  font-size: 1.5rem !important;
  font-weight: bold !important;
  text-align: center !important;
}

.formContent {
  padding: 16px 20px !important;
  width: 34rem;
}

.center {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.vertical {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.circularProgress {
  color: white;
  height: 7px;
  width: 10px;
}

.documentCategory {
  background-color: white !important;
  padding: 1rem !important;
  border-radius: 1rem !important;
}

.newPurchaseContainer {
  height: 90vh;
  width: 90vw;
}

.paper {
  min-width: 90vw !important;
  min-height: 85vh;
}

.genericForm {
  min-width: 60vw !important;
  min-height: 65vh;
}

.genericForm_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.genericForm_column {
  margin: 0 2rem;
}

.closeButton {
  cursor: pointer;
  background-color: $primaryGrey2;
  padding: 0.4rem;
  border-radius: 6px;
  &:hover {
    background-color: $primaryGrey3;
  }
}

.inputComponent {
  margin: 1rem 0px;
  border-radius: 12px;
}

.forms_inputComponent {
  margin: 0.5rem 1.2rem;
  width: 43%;
  display: inline-block;
}

.label {
  font-size: 0.8rem;
  margin: 0.2rem;
}

.input {
  border: 1px solid grey;
  border-radius: 10px;
  width: 100%;
  padding-left: 1rem;
  color: textColor;
  font-size: 0.8rem;
}

.inputSelect {
  height: 35px;
  border-radius: 5px !important;
  border: 1px solid grey;
  background-color: $primaryGrey2;
  padding: 0.2rem 0 0.2rem 1rem;
  color: textColor;
  font-size: 0.8rem;
}

.formSelect {
  border: 1px solid $primaryGrey;
  border-radius: 5px;
  width: 100%;
  padding: 0.2rem 0 0.2rem 1rem;
  color: textColor;
  font-size: 0.8rem;
  font-weight: bold;
  background-color: $primaryGrey2;
  color: $textColor;
}

.dateInput {
  // height:35px !important;
  padding: 0px;
  border: 1px solid grey;
  border-radius: 10px !important;
  width: 97%;
  padding-left: 1rem;
  // font-size: 0.6rem;
  // background:red;
}

.dateInput input {
  padding: 10px !important;
}

.form_input {
  border: 1px solid $primaryGrey;
  border-radius: 5px;
  width: 100%;
  padding: 0.2rem 0 0.2rem 1rem;
  color: textColor;
  font-size: 0.8rem;
  font-weight: bold;
  background-color: $primaryGrey2;
  color: $textColor;
}

.confirmButton {
  background-color: $darkBlue;
  margin: 0 0 1.5rem 0.5rem !important;
  width: 20%;
  border-radius: 1.2rem;
}

.form_title {
  margin-top: 1.5rem;
  font-weight: bold;
  font-size: 1.4rem;
  text-transform: uppercase;
}

.confirmModal {
  min-width: 30vw;
  min-height: 65vh;
}

.confirmModal_Confirm {
  background-color: $deleteRed2 !important;
  padding: 0.5rem 3.5rem;
  margin-bottom: 1rem;
  text-transform: none;
  &:hover {
    background-color: $deleteRed2 !important;
  }
}

.confirmModal_title {
  font-size: 1.4rem !important;
  font-weight: bold !important;
  color: $textColor;
}
