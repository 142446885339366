$textColor: #545252;
$primaryBlue: #4084d8;
$primaryTextColor: #9e9e9e;

.aRight {
  width: 100%;
  display: flex;
  justify-content: right;
}

.cPointer {
  cursor: pointer;
}

.header {
  margin-top: 1rem;
  margin-bottom: 2rem;
  font-size: 1.7rem;
  font-weight: bold;
  text-align: center;
}

.shopProjectCard {
  height: 17rem;
  width: 18rem;
  background-color: white;
  border-radius: 0.5rem;
  margin: 3rem 2rem;
  display: inline-block;
  cursor: pointer;
}

.shopProjectCardImage {
}
