$primaryBlue: #4084d8;

.chartContainer {
  height: 25vh;
  width: 100%;
  background-color: white;
  margin: 2rem 0;
}

.cpointer {
  cursor: pointer;
}

.pCenter {
  width: 100%;
  display: flex;
  justify-content: center;
}

.pRight {
  width: 100%;
  display: flex;
  justify-content: end;
}

.chatContainer {
  height: 45vh;
  width: 100%;
  background-color: white;
  margin: 1rem;
}

.shopHeader {
  display: flex;
  justify-content: spaceBetween;
}

.grow {
  flex-grow: 1;
}

.menuButton {
  margin-right: 20px;
}

.title {
  display: none;
}

.statsCardContainer {
  height: 25vh;
  width: 100%;
  margin: 3rem 0;
}

.dashboardStatCard {
  background-color: white;
  border-radius: 1rem;
}

.search {
  position: relative;
  border-radius: 2rem;
  background-color: white;
  &:hover {
    background-color: white;
  }
  // margin-right: theme.spacing(2);
  margin-left: 0;
  width: 100%;
}

.searchIcon {
  padding: 10px;
  height: 100%;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.inputRoot {
  color: inherit;
}

.actionsIcon {
  cursor: pointer !important;
  border: none;
  background-color: transparent;
}

.navTitle {
  color: #2c358d;
  font-weight: bold !important;
  margin-top: 2rem !important;
}

.inputInput {
  padding: 10px;
  // vertical padding + font size from searchIcon
  padding-left: 10px;
  // transition: theme.transitions.create(width);
  width: 100%;
}

.sectionDesktop {
  display: none;
  margin-left: auto;
  margin-right: 2rem;
}

.sectionMobile {
  display: flex;
}

.center {
  width: 100%;
  display: flex;
  justify-content: center;
}

.salesTopActions {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.salesTopActionsLeft {
  display: flex;
}

.salesTopActionsRight {
  display: flex;
}

.newSaleButton {
  background-color: $primaryBlue;
  color: white;
  margin-left: 1rem;
  &:hover {
    background-color: $primaryBlue;
  }
}

.normalButton {
  background-color: white;
  border: 2px solid transparent;
  &:hover {
    background-color: white;
    border: 2px solid $primaryBlue;
  }
}

.formButton {
  background-color: $primaryBlue;
  color: white;
  font-size: 0.7rem;
  padding: 4px 2rem;
  margin-bottom: 1.5rem;
  border-radius: 0.5rem;
}

.formHeader {
  background-color: $primaryBlue;
  width: 100%;
  height: 5rem;
  font-size: 4rem;
}

.formHeaderText {
  color: white;
  font-size: 2rem;
  font-weight: bold;
}

.formContent {
  padding-top: 2rem;
}

.itemsCategoryBox {
  display: flex;
  align-items: center;
}
