.table {
  // minWidth: 530,
  width: 100%;
}

.customTable {
  .MuiTableCell-sizeSmall {
    padding: 6px 0px 6px 16px; // <-- arbitrary value
  }
}

.actionsIcon {
  cursor: pointer;
  border: none;
  background-color: transparent;
}
