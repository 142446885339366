$blueBackground: #e8f2fc;

.link {
  text-decoration: none;
  display: flex;
  color: textColor;
}

.listItem {
  padding-top: '3px';
  padding-bottom: '3px';
}

.listItemIcon {
  min-width: '2rem';
}

.listItemSelected {
  color: darkBlue;
  background-color: $blueBackground !important;
}

.root {
  &:selected {
    background-color: $blueBackground;
    &:hover {
      background-color: $blueBackground;
    }
  }
}
