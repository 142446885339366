.loginContainer {
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
}

.loginContainerTwo {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
    315deg,
    rgba(178, 230, 255, 0.3) 26%,
    rgba(25, 118, 209, 0.41082370448179273) 52%,
    rgba(255, 255, 255, 0.6) 76%
  );
}

.inputFields {
  width: 80%;
  border-radius: 30px;
  margin: 1rem;
}

.centerText {
  text-align: center;
}

.loginText {
  font-size: 1.7rem;
  font-weight: bold;
  border-bottom: 2px solid #2200ff;
}

.loginBox {
  width: 30rem;
  min-height: 30rem;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loginBoxContent {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 50px;
}

.loginButton {
  background-color: darkBlue;
  color: white;
  width: 80%;
  margin: 1rem auto;
}

.spaceBetween {
  width: 80%;
  display: flex;
  justify-content: space-between;
}
